import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { AllInbox, Home, Notifications } from "@material-ui/icons";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PUBLIC_PATHS } from "../utils";
import { AuthContext } from "./Auth";

export default function BottomNav() {
  const { currentUser, wildcard } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const isPathPublic = PUBLIC_PATHS.includes(location.pathname);

  function handleChange() {
    switch (location.pathname) {
      case "/":
        return setValue(0);
      case "/notices":
        return setValue(1);
      case "/posts":
        return setValue(2);
      default:
        return setValue(99);
    }
  }

  useEffect(() => {
    handleChange();
  }, [location]);

  if (!currentUser || !wildcard || isPathPublic) return <div></div>;

  return (
    <BottomNavigation
      value={value}
      onChange={() => handleChange()}
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: "1px solid #f5f5f5",
      }}
    >
      <BottomNavigationAction
        label="Home"
        icon={<Home />}
        onClick={() => history.push("/")}
      />
      <BottomNavigationAction
        label="Notices"
        icon={<Notifications />}
        onClick={() => history.push("/notices")}
      />
      <BottomNavigationAction
        label="Discussions"
        icon={<AllInbox />}
        onClick={() => history.push("/posts")}
      />
    </BottomNavigation>
  );
}
