import {
  GroupActionStatus,
  ICreateGroup,
  IGroupAction,
  IGroupMember,
  IReadGroup,
} from "../dto/group.dto";
import firebase from "../firebase/base";
import { getWildcardName } from "../utils";
import NotificationService from "./notification";
import UserService from "./user";

export interface IGroupWiseSummary {
  name: string;
  noOfAllActions: number;
  noOfPendingActions: number;
  noOfInprogressActions: number;
  noOfDoneActions: number;
}

export interface IStats {
  groupWiseSummary: IGroupWiseSummary[];
  totalSummary: {
    totalAll: number;
    totalPending: number;
    totalInprogress: number;
    totalDone: number;
  };
}

class GroupService {
  private static instance: GroupService;

  static getInstance(): GroupService {
    if (!GroupService.instance) GroupService.instance = new GroupService();
    return GroupService.instance;
  }

  private db = firebase.firestore();

  private groupRef = this.db.collection("groups");

  private domain = getWildcardName();

  async createGroup(data: ICreateGroup, createrPhone: string) {
    try {
      if (!this.domain) throw new Error("No domain found.");

      const groupMembers: IGroupMember[] = [
        { phone: createrPhone, isGroupLeader: true },
      ];

      data.members.forEach((member) => groupMembers.push({ phone: member }));

      const submitData: any = {
        ...data,
        members: groupMembers,
        joinRequests: [],
        actions: [],
        domain: this.domain,
        createdAt: new Date().toISOString(),
      };

      const groupRef = this.groupRef.doc();
      // const imageUrl =
      //   data.img && (await this.addGroupImage(groupRef.id, data.img));

      // if (imageUrl) submitData.image = imageUrl;

      await groupRef.set(submitData);

      for (let member of data.members) {
        const user = await UserService.getUser(member);

        user &&
          (await this.addTokenAndNotify(
            [member],
            groupRef.id,
            data.name,
            user.name
          ));
      }

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async getGroup(id: string) {
    try {
      const res = (await this.db.doc(`/groups/${id}`).get()).data();

      return {
        id,
        ...res,
      } as IReadGroup;
    } catch (error) {
      console.error(error);
    }
  }

  async getGroupMembers(id: string) {
    try {
      const group: any = (await this.db.doc(`/groups/${id}`).get()).data();

      return group.members;
    } catch (error) {
      console.error(error);
    }
  }

  async getExcludedGroupMembers(id: string) {
    try {
      const group: any = (await this.db.doc(`/groups/${id}`).get()).data();

      if (!group) return;

      const groupMembers = group.members;

      const allMembersFetch = await this.db
        .collection("users")
        .where("domains", "array-contains-any", ["*", this.domain])
        .get();

      const allMembers: any = [];

      allMembersFetch.forEach((user: any) => {
        const findMem = groupMembers.find(
          (e: any) => e.phone === user.data().phone
        );

        if (!findMem) {
          allMembers.push({
            name: user.data().name,
            phone: user.data().phone,
          });
        }
      });

      return allMembers;
    } catch (error) {
      console.error(error);
    }
  }

  async listGroups() {
    try {
      if (!this.domain) return;

      const groups = await this.groupRef
        .where("domain", "==", this.domain)
        .get();

      let res: IReadGroup[] = [];

      groups.forEach((group: any) => {
        res.push({
          id: group.id,
          ...group.data(),
        });
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async joinGroup(
    groupDetail: { id: string; name: string; isOpen: boolean },
    data: { name: string; phone: string }
  ) {
    try {
      if (groupDetail.isOpen) {
        await this.db.doc(`groups/${groupDetail.id}`).update({
          members: firebase.firestore.FieldValue.arrayUnion({
            phone: data.phone,
          }),
        });

        return "JOINED";
      } else {
        await this.db.doc(`groups/${groupDetail.id}`).update({
          joinRequests: firebase.firestore.FieldValue.arrayUnion(data),
        });

        await NotificationService.notify(
          {
            title: `Someone's requesting permission to join ${groupDetail.name}.`,
            body: `${data.name}`,
          },
          `https://${this.domain}.koodey.com/groups/${groupDetail.id}`
        );

        return "REQUESTED";
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async handleMemberEntry(
    groupId: string,
    member: { phone: string; name: string },
    shouldAccept: boolean
  ) {
    try {
      const { phone, name } = member;

      await this.db.doc(`groups/${groupId}`).update({
        joinRequests: firebase.firestore.FieldValue.arrayRemove({
          phone,
          name,
        }),
      });

      if (shouldAccept) {
        await this.db.doc(`groups/${groupId}`).update({
          members: firebase.firestore.FieldValue.arrayUnion({
            phone,
          }),
        });

        const group: any = await this.getGroup(groupId);

        const user = await UserService.getUser(member.phone);

        user &&
          (await this.addTokenAndNotify(
            [member.phone],
            groupId,
            group?.name,
            user.name
          ));
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async addActions(
    userName: string,
    groupId: string,
    actions: { value: string }[]
  ) {
    try {
      const goalActions: any = [];

      actions.forEach((action) => {
        goalActions.push({
          action: action.value,
          status: "PENDING",
          createdAt: new Date().toISOString(),
        });
      });

      await this.db.doc(`/groups/${groupId}`).update({
        actions: firebase.firestore.FieldValue.arrayUnion(...goalActions),
      });

      const group: any = await this.getGroup(groupId);

      await NotificationService.notify(
        {
          title: `${userName} added a new action to your group.`,
          body: `${group?.name}`,
        },
        `https://${this.domain}.koodey.com/groups/${groupId}`
      );

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async updateActions(
    groupId: string,
    actions: any[],
    notificationDetails: { userName: string; action: IGroupAction }
  ) {
    const { userName, action } = notificationDetails;

    try {
      await this.db.doc(`/groups/${groupId}`).update({
        actions,
      });

      await NotificationService.notify(
        {
          title: `${userName} changed the status of an action.`,
          body: `${action.action}: ${action.status}`,
        },
        `https://${this.domain}.koodey.com/groups/${groupId}`
      );

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async deleteAction(groupId: string, action: any) {
    try {
      await this.db.doc(`/groups/${groupId}`).update({
        actions: firebase.firestore.FieldValue.arrayRemove(action),
      });

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async updateMembers(groupId: string, members: any) {
    try {
      await this.db.doc(`/groups/${groupId}`).update({
        members,
      });

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async removeMember(groupId: string, member: any) {
    try {
      await this.db.doc(`/groups/${groupId}`).update({
        members: firebase.firestore.FieldValue.arrayRemove(member),
      });

      await NotificationService.removeUserFromTopic(member.phone);

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async removeMemberByPhone(groupId: string, phone: string) {
    try {
      const group = await this.getGroup(groupId);
      if (!group) return;

      const members: IGroupMember[] = group.members;
      const updateMembers = members.filter((member) => member.phone !== phone);

      await this.updateMembers(groupId, updateMembers);
    } catch (error) {
      console.log(error);
    }
  }

  async removeMemberFromAllGroups(phone: string) {
    try {
      const groups = await this.listGroups();
      if (!groups) return;

      for (let group of groups) {
        const hasMember = group.members.some(
          (member) => member.phone === phone
        );

        if (hasMember) await this.removeMemberByPhone(group.id, phone);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async updateGroup(
    groupId: string,
    data: {
      name: string;
      goal: string;
      description: string;
      isOpen: boolean;
      image?: any;
    }
  ) {
    try {
      const submitData: any = {
        name: data.name,
        goal: data.goal,
        description: data.description,
        isOpen: data.description,
      };

      if (data.image) submitData.image = data.image;

      await this.db.doc(`/groups/${groupId}`).update(submitData);

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  async deleteGroup(groupId: string) {
    try {
      await this.db.doc(`/groups/${groupId}`).delete();

      return [true, null];
    } catch (error) {
      console.error(error);

      return [false, error];
    }
  }

  generateStats(groups: IReadGroup[]) {
    const groupWiseSummary: IGroupWiseSummary[] = [];

    const totalSummary = {
      totalAll: 0,
      totalPending: 0,
      totalInprogress: 0,
      totalDone: 0,
    };

    if (!groups) return { totalSummary, groupWiseSummary };

    for (let group of groups) {
      const name = group.name;
      const noOfAllActions = this.getLengthOfActions(group.actions);
      const noOfPendingActions = this.getLengthOfActions(
        group.actions,
        GroupActionStatus.PENDING
      );
      const noOfInprogressActions = this.getLengthOfActions(
        group.actions,
        GroupActionStatus.IN_PROGRESS
      );
      const noOfDoneActions = this.getLengthOfActions(
        group.actions,
        GroupActionStatus.DONE
      );

      groupWiseSummary.push({
        name,
        noOfAllActions,
        noOfPendingActions,
        noOfInprogressActions,
        noOfDoneActions,
      });

      totalSummary.totalAll += noOfAllActions;
      totalSummary.totalPending += noOfPendingActions;
      totalSummary.totalInprogress += noOfInprogressActions;
      totalSummary.totalDone += noOfDoneActions;
    }

    return { totalSummary, groupWiseSummary };
  }

  private getLengthOfActions(
    actions: IGroupAction[],
    status?: GroupActionStatus
  ) {
    if (!status) return actions.length;

    return actions.filter((e: IGroupAction) => e.status === status).length;
  }

  private async addTokenAndNotify(
    users: string[],
    groupId: string,
    groupName: string,
    userName?: string
  ) {
    const token = await NotificationService.getTokens(users);

    if (token.length > 0) {
      await NotificationService.addUserToTopic(token, false);
      await NotificationService.notify(
        {
          title: userName
            ? `${userName} has joined a new group.`
            : "A new member has joined your group.",
          body: `${groupName}`,
        },
        `https://${this.domain}.koodey.com/groups/${groupId}`
      );
    }
  }

  private async addGroupImage(id: string, image: string) {
    const imageRef = await firebase.storage().ref(`groups/${id}/icon.jpg`);

    await imageRef.putString(image, "data_url", {
      contentType: "image/jpg",
    });

    return await imageRef.getDownloadURL();
  }
}

export default GroupService.getInstance();
