import firebase from "../firebase/base";

class DomainService {
  private static instance: DomainService;

  static getInstance(): DomainService {
    if (!DomainService.instance) DomainService.instance = new DomainService();
    return DomainService.instance;
  }

  private db = firebase.firestore();

  private domainRef = this.db.collection("domains");

  async createDomain(data: any) {
    try {
      await this.db.doc(`/domains/${data.name}`).set({
        isVerified: false,
        ...data,
      });
      return true;
    } catch (error) {
      console.error(error);
    }
  }

  async getDomain(name: string) {
    try {
      const domain = await (await this.db.doc(`/domains/${name}`).get()).data();

      return domain;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async listDomains(onlyVerified = false) {
    try {
      let domains: any = [];

      if (onlyVerified)
        domains = await this.domainRef.where("isVerified", "==", true).get();
      else domains = await this.domainRef.get();

      let res: any[] = [];

      domains.forEach((domain: any) => {
        res.push({ id: domain.id, ...domain.data() });
      });

      return res;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async updateDomain(id: string, data: any) {
    try {
      await await this.db.doc(`/domains/${id}`).update(data);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async listUnverifiedDomains() {
    try {
      const domains = await this.domainRef
        .where("isVerified", "!=", true)
        .get();

      let res: any[] = [];

      domains.forEach((domain: any) => {
        res.push({ id: domain.id, ...domain.data() });
      });

      return res;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async checkDomainExists(name: string) {
    try {
      const domain = await this.db.doc(`/domains/${name}`).get();

      return domain.exists;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async removeDomain(name: string) {
    try {
      await this.db.doc(`/domains/${name}`).delete();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async updateDomainLogo(name: string, image: File) {
    try {
      const domain = this.getDomain(name);
      if (!domain) throw new Error(`Domain ${name} not found`);

      const extension = image.type.split("/")[1];
      const storageRef = firebase.storage().ref();
      const domainsRef = storageRef.child(`domains/${name}/logo.${extension}`);

      await domainsRef.put(image).catch((error) => {
        throw new Error(error.message);
      });

      const logo = await domainsRef.getDownloadURL();

      await this.updateDomain(name, {
        logo,
      });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async removeDomainLogo(name: string) {
    try {
      const domain = this.getDomain(name);
      if (!domain) throw new Error(`Domain ${name} not found`);

      await this.updateDomain(name, {
        logo: firebase.firestore.FieldValue.delete(),
      });

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}

export default DomainService.getInstance();
