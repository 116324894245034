import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  AllInbox,
  Book,
  Dns,
  Event,
  ExitToApp,
  Home,
  LiveHelp,
  Notifications,
  PeopleAltOutlined,
} from "@material-ui/icons";
import authService from "../api/auth";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./Auth";
import { MemberType } from "../dto/user";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

interface Props {
  isDrawerOpen: boolean;
  toggleDrawerHandler: () => void;
}

export default function Sidebar({ isDrawerOpen, toggleDrawerHandler }: Props) {
  const { userDetail } = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const aListItems = [
    {
      title: "Members",
      icon: <PeopleAltOutlined />,
      handler: () => {
        history.push("/members");
      },
    },
  ];

  const saListItems = [
    {
      title: "Domains",
      icon: <Dns />,
      handler: () => {
        history.push("/domains");
      },
    },
    {
      title: "Inquiries",
      icon: <LiveHelp />,
      handler: () => {
        history.push("/inquiries");
      },
    },
  ];

  const listItems = [
    {
      title: "Home",
      icon: <Home />,
      handler: () => {
        history.push("/");
      },
    },
    {
      title: "Notices",
      icon: <Notifications />,
      handler: () => {
        history.push("/notices");
      },
    },
    {
      title: "Events",
      icon: <Event />,
      handler: () => {
        history.push("/events");
      },
    },
    {
      title: "Discussions",
      icon: <AllInbox />,
      handler: () => {
        history.push("/posts");
      },
    },

    {
      title: "Newsletters",
      icon: <Book />,
      handler: () => {
        history.push("/blogs");
      },
    },
    // {
    //   title: "Logout",
    //   icon: <ExitToApp />,
    //   handler: () => {
    //     authService.logout();
    //   },
    // },
  ];

  useEffect(() => {
    setIsOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawerHandler}
      onKeyDown={toggleDrawerHandler}
    >
      <List>
        {listItems.map((item, index) => (
          <ListItem button key={item.title} onClick={item.handler}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}

        {userDetail &&
          (userDetail.userType === MemberType.SUPERADMIN ||
            userDetail.userType === MemberType.ADMIN) &&
          aListItems.map((item, index) => (
            <ListItem button key={item.title} onClick={item.handler}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}

        {userDetail &&
          userDetail.userType === MemberType.SUPERADMIN &&
          saListItems.map((item, index) => (
            <ListItem button key={item.title} onClick={item.handler}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        <ListItem
          button
          key="logout"
          onClick={() => {
            authService.logout();
          }}
        >
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      {/* <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  return (
    <Drawer anchor="left" open={isOpen} onClose={toggleDrawerHandler}>
      {list()}
    </Drawer>
  );
}
