import { MemberType } from "./dto/user";

export const getWildcardName = () => {
  const host = window.location.host;
  let parts = host.split(".");

  if (parts.length >= 3 && parts[0] !== "www") return parts[0];

  if (process.env.NODE_ENV === "development") return "abc";
  return null;
};

export const tryPromise = async <T>(promise: T) => {
  try {
    const res: T = await promise;

    return [res, null];
  } catch (error) {
    console.error(error);

    return [null, error];
  }
};

export const giveAllAdminAccess = (userType: MemberType | string | null) => {
  if (!userType) return false;

  if (userType === MemberType.SUPERADMIN || userType === MemberType.ADMIN) {
    return true;
  }

  return false;
};

export const PUBLIC_PATHS = ["/home", "/register-society"];
