import { MemberType } from "../dto/user";
import firebase from "../firebase/base";
import { getWildcardName } from "../utils";
import GroupService from "./group";

class UserService {
  private static instance: UserService;

  static getInstance(): UserService {
    if (!UserService.instance) UserService.instance = new UserService();
    return UserService.instance;
  }

  private domain = getWildcardName();
  private db = firebase.firestore();

  private userRef = this.db.collection("users");

  async getUser(phone: string): Promise<any> {
    try {
      const user = await (await this.db.doc(`/users/${phone}`).get()).data();

      const userType = user?.isSuperadmin
        ? MemberType.SUPERADMIN
        : await this.getUserType(phone);

      return { ...user, userType };
    } catch (error) {
      console.error(error);
    }
  }

  async getAllUsers(exclude: any[] = []) {
    try {
      const includedUsers = await this.db
        .collection("users")
        .where("domains", "array-contains-any", ["*", this.domain])
        .get();

      const unincludedUsers = await this.db
        .collection("users")
        .where("requestedDomains", "array-contains-any", [this.domain])
        .get();

      const includedRes: any = [];

      includedUsers.forEach((user: any) => {
        if (!exclude.includes(user.data().phone)) {
          let data: any = {
            name: user.data().name,
            phone: user.data().phone,
            isVerified: true,
            address: user.data().address,
          };

          if (user.data().societyRegNo) {
            data.societyRegNo = user.data().societyRegNo;
          }

          if (user.data().societyRole) {
            data.societyRole = user.data().societyRole;
          }

          includedRes.push(data);
        }
      });

      for (let i = 0; i < includedRes.length; i++) {
        const userType = await this.getUserType(includedRes[i].phone);
        includedRes[i].userType = userType;
      }

      const unincludedRes: any[] = [];

      unincludedUsers.forEach((user: any) => {
        if (!exclude.includes(user.data().phone)) {
          let data: any = {
            name: user.data().name,
            phone: user.data().phone,
            isVerified: false,
            address: user.data().address,
            userType: user.data().userType,
          };

          if (user.data().societyRegNo) {
            data.societyRegNo = user.data().societyRegNo;
          }

          if (user.data().societyRole) {
            data.societyRole = user.data().societyRole;
          }

          unincludedRes.push(data);
        }
      });

      return [...unincludedRes, ...includedRes];
    } catch (error) {
      console.error(error);
    }
  }

  async getUserFCMTokens() {
    try {
      const users = await this.db
        .collection("users")
        .where("domains", "array-contains-any", [this.domain])
        .get();

      const res: any = [];

      users.forEach((user) => {
        let data: any = {
          phone: user.data().phone,
        };

        res.push(data);
      });

      const tokens: string[] = [];

      for (let i = 0; i < res.length; i++) {
        const tkns = await this.getUserTokens(res[i].phone);

        if (tkns) tokens.push(...tkns);
      }

      return tokens;
    } catch (error) {
      console.error(error);
    }
  }

  async getCommitteeMembers() {
    try {
      const users = await this.db
        .collection("users")
        .where("domains", "array-contains-any", ["*", this.domain])
        // .where("isVerified", "==", true)
        .get();

      const res: any = [];

      users.forEach((user: any) => {
        res.push(user.data());
      });

      const committeeMembers: any = [];

      for (let i = 0; i < res.length; i++) {
        const committeeDesignation = await this.getCommitteeDesignation(
          res[i].phone
        );

        if (committeeDesignation)
          committeeMembers.push({
            ...res[i],
            committeeDesignation,
          });
      }

      return committeeMembers;
    } catch (error) {
      console.error(error);
    }
  }

  async removeCommitteeMember(phone: string) {
    if (!this.domain) return false;

    try {
      await this.db
        .doc(`users/${phone}`)
        .collection("domainMeta")
        .doc(this.domain)
        .update({
          committeeDesignation: firebase.firestore.FieldValue.delete(),
        });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async createCommitteeMember(phone: string, designation: string) {
    if (!this.domain) return false;

    try {
      await this.db
        .doc(`/users/${phone}`)
        .collection("domainMeta")
        .doc(this.domain)
        .update({
          committeeDesignation: designation,
        });

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }

  async getIncludedUsers(included: any[]) {
    try {
      const res: any = [];

      for (let member of included) {
        const user = (await this.db.doc(`users/${member}`).get()).data();

        if (user) {
          const userType = user?.isSuperadmin
            ? MemberType.SUPERADMIN
            : await this.getUserType(user.phone);

          res.push({
            name: user.name,
            phone: user.phone,
            isSuperadmin: user.isSuperadmin,
            profileImage: user.profileImage,
            userType,
          });
        }
      }

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async checkIsUserVerified(phone: string) {
    try {
      // const user = (await this.db.doc(`/users/${phone}`).get()).data();
      const existingUser = this.userRef
        .where("phone", "==", phone)
        .where("domains", "array-contains-any", ["*", this.domain])
        .get();

      const isEmpty = (await existingUser).empty;

      console.log({ isEmpty });

      if (!isEmpty) return true;

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getUnverifiedUsers() {
    try {
      const users = await this.db
        .collection("users")
        .where("domains", "array-contains-any", ["*", this.domain])
        // .where("isVerified", "==", true)
        .get();

      const unverifiedUsers: any = [];

      users.forEach((user: any) => {
        unverifiedUsers.push({
          name: user.data().name,
        });
      });

      return users;
    } catch (error) {
      console.error(error);
    }
  }

  async verifyUser(phone: string) {
    try {
      if (this.domain) {
        await this.db
          .doc(`/users/${phone}`)
          .collection("domainMeta")
          .doc(this.domain)
          .set({
            userType: MemberType.MEMBER,
          });

        await this.db.doc(`/users/${phone}`).update({
          // isVerified: true,
          domains: firebase.firestore.FieldValue.arrayUnion(this.domain),
          requestedDomains: firebase.firestore.FieldValue.arrayRemove(
            this.domain
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async removeUser(phone: string) {
    try {
      if (!this.domain) return false;

      await GroupService.removeMemberFromAllGroups(phone);

      await this.db.doc(`/users/${phone}`).update({
        domains: firebase.firestore.FieldValue.arrayRemove(this.domain),
        requestedDomains: firebase.firestore.FieldValue.arrayRemove(
          this.domain
        ),
      });

      await this.db
        .doc(`/users/${phone}`)
        .collection("domainMeta")
        .doc(this.domain)
        .delete();

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }

  async updateUser(values: any, phone: string) {
    try {
      await this.db.doc(`/users/${phone}`).update({ ...values });

      return [true, null];
    } catch (error) {
      console.log(error);
      return [null, "Something went wrong."];
    }
  }

  async toggleAdmin(phone: string) {
    try {
      if (!this.domain) return;

      const user = (await this.db.doc(`/users/${phone}`).get()).data();

      if (!user) return;

      const type = await this.getUserType(phone);

      const userType = this.checkIsAdmin(type)
        ? MemberType.MEMBER
        : MemberType.ADMIN;

      if (!this.domain) return;

      await this.db
        .doc(`/users/${phone}`)
        .collection("domainMeta")
        .doc(this.domain)
        .update({ userType });
    } catch (error) {
      console.log(error);
    }
  }

  checkIsAdmin(userType: MemberType) {
    if (userType === MemberType.ADMIN || userType === MemberType.SUPERADMIN)
      return true;
    return false;
  }

  async uploadProfileImage(phone: string, dataUrl: string) {
    try {
      const user = await this.getUser(phone);
      const storageRef = firebase.storage().ref(`users/${phone}/profile.jpg`);

      await storageRef.putString(dataUrl, "data_url", {
        contentType: "image/jpg",
      });
      const profileImage = await storageRef.getDownloadURL();

      await this.updateUser(
        {
          ...user,
          profileImage,
        },
        phone
      );
    } catch (error) {
      console.error(error);
    }
  }

  async downloadProfileImage(phone: string) {
    try {
      return await firebase
        .storage()
        .ref(`users/${phone}/profile.jpg`)
        .getDownloadURL();
    } catch (error) {
      console.error(error);
    }
  }

  private async getUserType(phone: string) {
    const domainMeta =
      this.domain &&
      (await (
        await this.db
          .doc(`/users/${phone}`)
          .collection("domainMeta")
          .doc(this.domain)
          .get()
      ).data());

    let userType = null;

    if (domainMeta && domainMeta.userType) userType = domainMeta.userType;
    else userType = MemberType.MEMBER;

    return userType;
  }

  async getUserTokens(phone: string): Promise<string[] | undefined> {
    const domainMeta =
      this.domain &&
      (await (
        await this.db
          .doc(`/users/${phone}`)
          .collection("domainMeta")
          .doc(this.domain)
          .get()
      ).data());

    const domainToken = domainMeta && domainMeta.fcmTokens;
    let tokens = [];

    if (domainToken) tokens.push(...domainToken);

    return tokens;
  }

  private async getCommitteeDesignation(phone: string) {
    const domainMeta =
      this.domain &&
      (await (
        await this.db
          .doc(`/users/${phone}`)
          .collection("domainMeta")
          .doc(this.domain)
          .get()
      ).data());

    let designation = null;

    if (domainMeta && domainMeta.committeeDesignation)
      designation = domainMeta.committeeDesignation;

    return designation;
  }
}

export default UserService.getInstance();
