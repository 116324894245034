export interface IUser {
  name: string;
  email?: string;
  phone: string;
  address: string;
  societyRegNo?: string;
  societyRole?: string;
  isVerified?: boolean;
  bloodGroup?: string;
  userType: MemberType;
  committeeDesignation?: string;
  profileImage?: string;
  domains?: any[];
  requestedDomains?: any[];
  profession: string;
  createdAt: string;
}

export type ICreateUser = Omit<IUser, "userType">;

export enum MemberType {
  MEMBER = "MEMBER",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
}
