import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";
import firebaseConfig from "./firebase.config";

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const messaging =
  firebase.messaging.isSupported() && firebase.messaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging &&
      messaging.onMessage((payload: any) => {
        resolve(payload);
      });
  });

export default firebase;
