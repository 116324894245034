import { useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Avatar } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { useQuery } from "react-query";
import Sidebar from "../Sidebar";
import DomainService from "../../api/domain";
import { AuthContext } from "../Auth";
import { useHistory, useLocation } from "react-router-dom";
import { getWildcardName, PUBLIC_PATHS } from "../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    avatar: {
      height: "24px",
      width: "24px",
    },
  })
);

const fetchDomain = async () => {
  const name = getWildcardName();
  if (!name) return;

  const domain = await DomainService.getDomain(name);
  if (domain) return domain;
};

export default function KoodeyAppBar() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div data-testid="appBar">
      <div className={classes.root}>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "white", color: "#008080" }}
        >
          {_renderToolBar({ ...authCtx }, { toggleDrawer, history }, classes)}
        </AppBar>
      </div>
      <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawerHandler={toggleDrawer} />
    </div>
  );
}

function _renderToolBar(
  authCtx: {
    currentUser: any;
    userDetail: any;
    wildcard: string | null;
    isVerified: boolean;
  },
  handler: { toggleDrawer: () => void; history: any },
  classes: any
) {
  const { data } = useQuery(["domain"], fetchDomain, {
    refetchOnWindowFocus: false,
  });
  const { currentUser, wildcard, userDetail, isVerified } = authCtx;
  const { toggleDrawer, history } = handler;
  const location = useLocation();
  const isPathPublic = PUBLIC_PATHS.includes(location.pathname);

  console.log({ data });

  return (
    <Toolbar>
      {!!currentUser && wildcard && !isPathPublic && isVerified && (
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      )}

      {data?.logo ? (
        <img
          src={data.logo}
          alt={data.name}
          style={{
            maxHeight: "48px",
            margin: "8px",
          }}
        />
      ) : (
        <Typography variant="h6" className={classes.title}>
          Koodey
        </Typography>
      )}

      <div className={classes.grow} />

      {!!currentUser && wildcard && !isPathPublic && isVerified && (
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-controls={"primary-search-account-menu"}
          aria-haspopup="true"
          onClick={() => history.push("/profile")}
          color="inherit"
        >
          {userDetail?.profileImage ? (
            <Avatar
              className={classes.avatar}
              alt={userDetail.name}
              src={userDetail.profileImage}
            />
          ) : (
            <AccountCircle />
          )}
        </IconButton>
      )}
    </Toolbar>
  );
}
