import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import KoodeyAppBar from "./components/kdAppBar/KoodeyAppBar";
import { AuthContext } from "./components/Auth";
import rootReducer from "./redux/reducers";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import BottomNav from "./components/BottomNav";
import { createTheme, Snackbar, ThemeProvider } from "@material-ui/core";
import { Suspense, useContext, useState } from "react";
import renderRoutes from "./routes";
import { onMessageListener } from "./firebase/base";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Loading from "./components/Loading";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1dd3b0",
    },
    text: {
      primary: "#444444",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#ffffff",
      },
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const store = createStore(rootReducer, applyMiddleware(thunk));

function App() {
  const { isVerified } = useContext(AuthContext);
  const [notification, setNotification] = useState("");

  function removeNotification() {
    setNotification("");
  }

  onMessageListener()
    .then((payload: any) => {
      if (payload?.data?.title) setNotification(payload.data.title);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={notification.length > 0 && true}
            onClose={removeNotification}
            transitionDuration={{ exit: 5000 }}
            message={notification}
          />
          <Suspense fallback={<Loading />}>
            <Router>
              <KoodeyAppBar />
              {renderRoutes()}
              {isVerified && <BottomNav />}
            </Router>
          </Suspense>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
