import { ICreateUser, MemberType } from "../dto/user";
import firebase from "../firebase/base";
import { getWildcardName } from "../utils";
import NotificationService from "./notification";

class AuthService {
  private static instance: AuthService;

  static getInstance(): AuthService {
    if (!AuthService.instance) AuthService.instance = new AuthService();
    return AuthService.instance;
  }

  private domain = getWildcardName();
  private db = firebase.firestore();

  private userRef = this.db.collection("users");

  async logout() {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error(error);
    }
  }

  async checkIfUserExists(phone: string) {
    try {
      const res = await this.userRef
        .where("phone", "==", phone)
        .where("domains", "array-contains-any", ["*", this.domain])
        .get();

      if (res.empty) return false;
      return true;
    } catch (error) {
      console.log(error);
      return "error";
    }
  }

  async checkIfUserExistsGlobally(phone: string) {
    try {
      const res = await this.userRef.where("phone", "==", phone).get();

      if (res.empty) return false;
      return true;
    } catch (error) {
      console.log(error);
      return "error";
    }
  }

  async signup(values: ICreateUser, isAdmin = false, isCreatedByAdmin = false) {
    try {
      if (!this.domain) return [false, "Something went wrong"];

      const existingUser = await this.checkIfUserExists(values.phone);
      const globalExistingUser = await (
        await this.db.doc(`/users/${values.phone}`).get()
      ).data();

      if (existingUser === "error") return [null, "Something went wrong"];
      if (existingUser) return [null, "Phone number already exists"];

      let submitValues: any = {
        ...values,
        domains: [],
        requestedDomains: [],
      };

      if (globalExistingUser) {
        submitValues.domains =
          globalExistingUser.domains && globalExistingUser.domains;
        submitValues.requestedDomains =
          globalExistingUser.requestedDomains &&
          globalExistingUser.requestedDomains;
      }

      if (isCreatedByAdmin) {
        submitValues.domains.push(this.domain);
      } else {
        submitValues.requestedDomains.push(this.domain);
      }

      if (globalExistingUser) {
        await this.db.doc(`/users/${values.phone}`).update(submitValues);
      } else {
        await this.db.doc(`/users/${values.phone}`).set({
          ...submitValues,
          createdAt: new Date().toISOString(),
        });
      }

      const userType = isAdmin ? MemberType.ADMIN : MemberType.MEMBER;

      await this.db
        .doc(`/users/${values.phone}`)
        .collection("domainMeta")
        .doc(this.domain)
        .set({
          userType,
        });

      if (!isCreatedByAdmin) {
        NotificationService.notify(
          {
            title: "You have a new member request.",
            body: `${values.name} wishes to become a part of your association.`,
          },
          `https://${this.domain}.koodey.com/members`,
          true
        );
      }

      return [true, null];
    } catch (error) {
      console.error(error);
      return [null, "Something went wrong"];
    }
  }
}

export default AuthService.getInstance();
