export interface ICreateGroup {
  name: string;
  members: string[];
  goal: string;
  description: string;
  isOpen: boolean;
  image?: any;
}

export interface IReadGroup {
  id: string;
  name: string;
  members: IGroupMember[];
  actions: IGroupAction[];
  goal: string;
  description: string;
  joinRequests: { name: string; phone: string }[];
  isOpen: boolean;
  image?: string;
}

export interface IGroupAction {
  action: string;
  status: GroupActionStatus;
  createdAt: string;
}

export interface IGroupMember {
  phone: string;
  isGroupLeader?: boolean;
}

export enum GroupActionStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN PROGRESS",
  DONE = "DONE",
}
