// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALy2YtzhsO3jFB9Z0rtGnm0AbwTTG8XVs",
  authDomain: "koodey-a82a0.firebaseapp.com",
  projectId: "koodey-a82a0",
  storageBucket: "koodey-a82a0.appspot.com",
  messagingSenderId: "103844727650",
  appId: "1:103844727650:web:e474f07639a55e32fdba3b",
  measurementId: "G-M6XPKKH8D2",
};

export default firebaseConfig;
