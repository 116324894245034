import axios from "axios";
import firebase from "../firebase/base";
import { getWildcardName } from "../utils";
import UserService from "./user";

class NotificationService {
  private static instance: NotificationService;

  static getInstance(): NotificationService {
    if (!NotificationService.instance)
      NotificationService.instance = new NotificationService();
    return NotificationService.instance;
  }

  private db = firebase.firestore();

  private domain = getWildcardName();

  async initNotification(phone: string, isAdmin = false, isSuperadmin = false) {
    try {
      const messaging = await firebase.messaging();
      const token = await messaging.getToken({
        vapidKey:
          "BPKeVVr2tN7ooZJJPW0t32CMB3CEvE94JVV3yU9utsJOIDC-9phwXg2o2C50uFQtlGLAxOrfi_D2RXyTbbnELL8",
      });

      if (token && phone) {
        await this.storeFCMToken(phone, token);
        await this.addUserToTopic(token, isAdmin, isSuperadmin);
      }

      return token;
    } catch (error) {
      console.error(error);
    }
  }

  async storeFCMToken(phone: string, token: string) {
    if (!this.domain || !phone || !token) return false;

    try {
      let fcmTokens = [];
      let tokens = await this.getTokens([phone]);
      let tokensToRemove: string[] = [];

      if (tokens.length > 4) {
        tokensToRemove = tokens.slice(0, 4);
        tokens = tokens.slice(4);
      }

      if (tokensToRemove.length > 0) this.removeUserFromTopic(tokensToRemove);

      fcmTokens = [...tokens, token];

      await this.db
        .doc(`/users/${phone}`)
        .collection("domainMeta")
        .doc(this.domain)
        .update({ fcmTokens });
    } catch (error) {
      console.error(error);
    }
  }

  async addUserToTopic(
    token: string | string[],
    isAdmin = false,
    isSuperadmin = false
  ) {
    if (!this.domain) return false;

    await axios.post(
      "https://asia-south1-koodey-a82a0.cloudfunctions.net/addUserToTopic",
      {
        token: token,
        topic: this.domain,
      }
    );

    if (isAdmin) {
      await axios.post(
        "https://asia-south1-koodey-a82a0.cloudfunctions.net/addUserToTopic",
        {
          token: token,
          topic: `${this.domain}-admin`,
        }
      );
    }

    if (isSuperadmin) {
      await axios.post(
        "https://asia-south1-koodey-a82a0.cloudfunctions.net/addUserToTopic",
        {
          token: token,
          topic: "superadmin",
        }
      );
    }
  }

  async removeUserFromTopic(token: string[] | string) {
    if (!this.domain) return false;

    await axios.post(
      "https://asia-south1-koodey-a82a0.cloudfunctions.net/removeUserFromTopic",
      {
        token: token,
        topic: this.domain,
      }
    );
  }

  async notify(
    notifText: { title: string; body: string },
    link?: string | false,
    forAdmin = false
  ) {
    if (process.env.NODE_ENV === "development") return;

    const { title, body } = notifText;

    if (!this.domain) return false;

    let postData: Record<string, string> = {
      topic: forAdmin ? `${this.domain}-admin` : this.domain,
      title,
      body,
    };

    if (link) postData.link = link;

    await axios.post(
      "https://asia-south1-koodey-a82a0.cloudfunctions.net/notifyTopic",
      postData
    );
    // .then((res) => console.log(res))
    // .catch((err) => console.log(err));
  }

  async getTokens(users: string[]) {
    const tokens: string[] = [];

    for (let user of users) {
      const userTokens = await UserService.getUserTokens(user);

      if (userTokens) tokens.push(...userTokens);
    }

    return tokens;
  }
}

export default NotificationService.getInstance();
