import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import app from "../firebase/base";
import UserService from "../api/user";
import { getWildcardName } from "../utils";
import DomainService from "../api/domain";

export const AuthContext = React.createContext({
  currentUser: null,
  userDetail: {
    userType: null,
    phone: "",
    name: "",
    profileImage: "",
  },
  wildcard: null,
  isVerified: false,
});

export const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser]: any = useState(null);
  const [userDetail, setUserDetail]: any = useState(null);
  const [wildcard, setWildCard]: any = useState(null);
  const [pending, setPending] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  async function getWildcard() {
    const domain = getWildcardName();

    if (!domain) return null;

    const res = await DomainService.getDomain(domain);

    if (res && res.isVerified) setWildCard(domain);
  }

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (user?.phoneNumber) {
        const details = await UserService.getUser(user.phoneNumber.slice(3));
        const isverified = await UserService.checkIsUserVerified(
          user.phoneNumber.slice(3)
        );
        setUserDetail(details);
        setIsVerified(isverified);
      }

      await getWildcard();
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, userDetail, wildcard, isVerified }}
    >
      {children}
    </AuthContext.Provider>
  );
};
