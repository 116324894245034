import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AuthContext } from "./Auth";

interface Props {
  component: JSX.IntrinsicElements;
}

const PrivateRoute = ({ component: RouteComponent, ...rest }: Props & any) => {
  const { currentUser, wildcard, userDetail, isVerified } =
    useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!wildcard) {
          return <Redirect to={"/home"} />;
        } else {
          if (!!currentUser) {
            return <RouteComponent {...routeProps} />;
          } else {
            return <Redirect to={"/login"} />;
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
