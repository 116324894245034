import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

const SocietyHome = React.lazy(
  () => import("./screens/home/societyHome/SocietyHomeContainer")
);
const KoodeyHome = React.lazy(() => import("./screens/home/KoodeyHome"));
const Login = React.lazy(() => import("./screens/Login"));
const Signup = React.lazy(() => import("./screens/Signup"));
const AddMemberScreen = React.lazy(
  () => import("./screens/addMember/AddMemberContainer")
);
const MemberTable = React.lazy(
  () => import("./screens/memberTable/MemberTable")
);
const EditMemberScreen = React.lazy(() => import("./screens/EditMember"));
const ProfileScreen = React.lazy(() => import("./screens/ProfileScreen"));
const Posts = React.lazy(() => import("./screens/Posts"));
const BlogList = React.lazy(
  () => import("./screens/home/blog/blogList/BlogList")
);
const CreateBlogScreen = React.lazy(
  () => import("./screens/home/blog/createBlog/CreateBlogScreen")
);
const Blog = React.lazy(() => import("./screens/Blog"));
const CreateNoticeScreen = React.lazy(
  () => import("./screens/CreateNoticeScreen")
);
const Notices = React.lazy(() => import("./screens/Notices"));
const GroupListScreen = React.lazy(
  () => import("./screens/group/groupListScreen/GroupListScreenContainer")
);
const CreateGroupScreen = React.lazy(
  () => import("./screens/group/createGroupScreen/CreateGroupScreen")
);
const GroupScreen = React.lazy(
  () => import("./screens/group/groupScreen/GroupScreenContainer")
);
const CreateGoalScreen = React.lazy(() => import("./screens/CreateGoalScreen"));
const GroupMembers = React.lazy(
  () => import("./screens/groupMembers/GroupMembers")
);
const AddGroupMembers = React.lazy(
  () => import("./screens/AddGroupMembersScreen")
);
const GroupChat = React.lazy(() => import("./screens/GroupChat"));
const GroupSettings = React.lazy(() => import("./screens/GroupSettings"));
const CreateEvent = React.lazy(() => import("./screens/CreateEvent"));
const Events = React.lazy(() => import("./screens/Events"));
const CreateIE = React.lazy(() => import("./screens/CreateIE"));
const IncomeStatement = React.lazy(
  () => import("./screens/income/IncomeStatement")
);
const UpdateIE = React.lazy(() => import("./screens/UpdateIE"));
const CreateCommitteeMember = React.lazy(
  () => import("./screens/CreateCommitteeMember")
);
const CommitteeMembers = React.lazy(() => import("./screens/CommitteeMembers"));
const CreatePoll = React.lazy(() => import("./screens/CreatePoll"));
const Polls = React.lazy(() => import("./screens/polls/PollsContainer"));
const CreateNearby = React.lazy(() => import("./screens/CreateNearby"));
const NearbyList = React.lazy(() => import("./screens/NearbyList"));
const EditNearby = React.lazy(() => import("./screens/EditNearby"));
const CreateDomain = React.lazy(() => import("./screens/CreateDomain"));
const Domains = React.lazy(() => import("./screens/DomainListScreen"));
const RegisterSociety = React.lazy(
  () => import("./screens/registerSociety/RegisterSocietyContainer")
);
const SurveyListScreenContainer = React.lazy(
  () => import("./screens/survey/surveyListScreen/SurveyListScreenContainer")
);
const CreateSurveyScreen = React.lazy(
  () => import("./screens/survey/CreateSurveyScreen")
);
const SurveyScreenContainer = React.lazy(
  () => import("./screens/survey/surveyScreen/SurveyScreenContainer")
);
const SurveyListResultContainer = React.lazy(
  () => import("./screens/survey/surveyListResult/SurveyListResultContainer")
);
const SurveySubmissions = React.lazy(
  () => import("./screens/survey/SurveySubmissions")
);
const Submission = React.lazy(() => import("./screens/survey/Submission"));
const GoalStats = React.lazy(() => import("./screens/goalStats/GoalStats"));
const InquiryScreen = React.lazy(() => import("./screens/Inquiry"));
const Chat = React.lazy(() => import("./screens/Chat"));

interface IRoute {
  path: string;
  component: React.LazyExoticComponent<(props: any) => JSX.Element>;
  isPrivate?: boolean;
}

export default function renderRoutes() {
  return (
    <Switch>
      {routes.map((route, idx) => {
        if (route.isPrivate) {
          return (
            <PrivateRoute
              key={idx}
              exact
              path={route.path}
              component={route.component}
            />
          );
        } else {
          return (
            <Route
              key={idx}
              exact
              path={route.path}
              component={route.component}
            />
          );
        }
      })}
    </Switch>
  );
}

const routes: IRoute[] = [
  {
    path: "/",
    component: SocietyHome,
    isPrivate: true,
  },
  {
    path: "/home",
    component: KoodeyHome,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/register-society",
    component: RegisterSociety,
  },
  {
    path: "/admin/add-member",
    component: AddMemberScreen,
    isPrivate: true,
  },
  {
    path: "/members",
    component: MemberTable,
    isPrivate: true,
  },
  {
    path: "/members/update",
    component: EditMemberScreen,
    isPrivate: true,
  },
  {
    path: "/profile",
    component: ProfileScreen,
    isPrivate: true,
  },
  {
    path: "/posts",
    component: Posts,
    isPrivate: true,
  },
  {
    path: "/blogs",
    component: BlogList,
    isPrivate: true,
  },
  {
    path: "/blog/create",
    component: CreateBlogScreen,
    isPrivate: true,
  },
  {
    path: "/blogs/:id",
    component: Blog,
    isPrivate: true,
  },
  {
    path: "/notice/create",
    component: CreateNoticeScreen,
    isPrivate: true,
  },
  {
    path: "/notices",
    component: Notices,
    isPrivate: true,
  },
  { path: "/groups", component: GroupListScreen, isPrivate: true },
  {
    isPrivate: true,
    path: "/group/create",
    component: CreateGroupScreen,
  },
  { isPrivate: true, path: "/groups/:id", component: GroupScreen },
  {
    isPrivate: true,
    path: "/goal/create",
    component: CreateGoalScreen,
  },
  {
    isPrivate: true,
    path: "/groups/:id/members",
    component: GroupMembers,
  },
  {
    isPrivate: true,
    path: "/groups/:id/members/add",
    component: AddGroupMembers,
  },
  {
    isPrivate: true,
    path: "/groups/:id/chat",
    component: GroupChat,
  },
  {
    isPrivate: true,
    path: "/groups/:id/settings",
    component: GroupSettings,
  },
  {
    isPrivate: true,
    path: "/event/create",
    component: CreateEvent,
  },
  { isPrivate: true, path: "/events", component: Events },
  { isPrivate: true, path: "/income/create", component: CreateIE },
  { isPrivate: true, path: "/incomes", component: IncomeStatement },
  { isPrivate: true, path: "/incomes/:id", component: UpdateIE },
  {
    isPrivate: true,
    path: "/committee-member/create",
    component: CreateCommitteeMember,
  },
  {
    isPrivate: true,
    path: "/committee-members",
    component: CommitteeMembers,
  },
  { isPrivate: true, path: "/poll/create", component: CreatePoll },
  { isPrivate: true, path: "/polls", component: Polls },
  {
    isPrivate: true,
    path: "/nearby/create",
    component: CreateNearby,
  },
  { isPrivate: true, path: "/nearbies", component: NearbyList },
  { isPrivate: true, path: "/nearbies/:id", component: EditNearby },
  {
    isPrivate: true,
    path: "/domain/create",
    component: CreateDomain,
  },
  { isPrivate: true, path: "/domains", component: Domains },

  // SURVEY
  { isPrivate: true, path: "/surveys", component: SurveyListScreenContainer },
  {
    isPrivate: true,
    path: "/survey/result",
    component: SurveyListResultContainer,
  },
  { isPrivate: true, path: "/survey/create", component: CreateSurveyScreen },
  { isPrivate: true, path: "/surveys/:id", component: SurveyScreenContainer },
  {
    isPrivate: true,
    path: "/survey/result/:surveyId/submissions",
    component: SurveySubmissions,
  },
  {
    isPrivate: true,
    path: "/survey/result/:surveyId/submissions/:submissionId",
    component: Submission,
  },
  {
    isPrivate: true,
    path: "/goals/stats",
    component: GoalStats,
  },
  {
    isPrivate: true,
    path: "/inquiries",
    component: InquiryScreen,
  },
  {
    isPrivate: true,
    path: "/chat",
    component: Chat,
  },
];
